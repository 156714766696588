import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { Header } from "../controls/Header";
import { JdContext } from "../helper/JdContext";
import { Box, Grid, Link, Typography } from "@mui/material";
import { theme } from "../helper/JdTheme";
import { IconDownload } from "../helper/Icons";

export default function Resume() {
	const context = useContext(JdContext);
	const [sectionTitleAlignment, setSectionTitleAlignment] = useState("left");
	const [linkColor, setLinkColor] = useState(theme.palette.primary.main);

	const sxImageBox = {
		width: "100%",
		maxWidth: "600px",
		minWidth: "400px",
		textAlign: "center",
	};

	//**********************************************************************************************
	// EFFECTS
	//**********************************************************************************************
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (context.mediaXS || context.mediaSM || context.mediaMD) setSectionTitleAlignment("justify");
		else setSectionTitleAlignment("left");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const ContentElement = () => {
		return (
			<React.Fragment>
				<Grid container sx={{ marginTop: "0px", paddingTop: "160px" }} spacing={4}>
					{/* PRODUCT CARDS */}
					<Grid item xs={12} md={6} pr={context.mediaMD ? 2 : 0} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Typography variant="h4" pb={0} sx={{ fontWeight: "800", color: theme.palette.primary.main }}>
							RESUME
						</Typography>
						<Typography variant="h3" mt={4}>
							What's not in the Resume
						</Typography>
						<Typography variant="body1" pb={0} pt={2} sx={{ fontWeight: "400" }}>
							A resume might not always capture the essence of who we truly are as a person. It's crucial to know if someone is friendly, toxic, or carries a positive attitude, right? After all, attitude plays a significant role in how well we work together as a team. Let me take this
							opportunity to share a bit about my personality.
							<br />
							<br />
							Above all else, I consider myself highly entrepreneurial in any role I take on. Give me the goals, and I'll deliver results. It's essential to mention this because sometimes I'm offered roles that deal with only a small segment of product management. However, I'm a
							creative soul, and I find my shine when I can contribute to a broader scope.
							<br />
							<br />
							My world isn't limited to the unorganized startup realm; I've also thrived in larger companies that provided an environment where I could be most productive. I'm selective about the positions I take, as I fully invest myself in projects I genuinely believe in. This
							passion enables me to give my all, putting in the extra effort whenever needed, whether it's 6 am or 1 am.
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} textAlign="right" justifyContent={context.mediaXS || context.mediaSM ? "center" : "right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={0} display={"flex"} justifyContent={context.mediaXS || context.mediaSM ? "center" : "right"}>
							<Box mt={4} sx={{ position: "relative" }}>
								<img src={"/resume.png"} alt="logo" width={context.mediaXS ? "400px" : "500px"} style={{ border: "1px solid #ccc" }} />
								<Box sx={{ backgroundColor: "none", width: context.mediaXS ? "400px" : "500px", height: "400px", position: "absolute", top: "20%", left: "1px", opacity: 1 }}>
									<Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
										<Box>
											<Typography variant="h2" fontWeight={"800"} sx={{ textAlign: "center" }}>
												<Link href="/Jozsef_David_Resume.pdf" target="_blank" onMouseOver={() => setLinkColor(theme.palette.secondary.main)} onMouseOut={() => setLinkColor(theme.palette.primary.main)}>
													<IconDownload width={300} height={300} fill={linkColor} stroke={"none"} />
												</Link>
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={0}>
							<Typography variant="body1" pt={0} sx={{ fontWeight: "400" }}>
								Throughout my 20+ years of experience in an international environment, I've had the privilege of working with people from various cultures, including the US, Europe, Japan, India, Australia, the Philippines, and more. It's fascinating to understand their diverse
								cultures and work standards, and it has taught me how to professionally navigate and collaborate with different backgrounds. Embracing this diversity has been a valuable asset in my career journey.
								<br />
								<br />
								I consider myself a practical individual, valuing real-world application over mere theory. However, it is vital to emphasize that understanding the theoretical foundations is paramount. Without a solid grasp of theory, achieving excellence in practice can be
								challenging. Striking a healthy balance between theory and practice is key, and I always apply practical insights derived from sound theoretical knowledge.
								<br />
								<br />
								In my approach, I also rely on common sense, which proves invaluable in decision-making and problem-solving. I often find myself customizing or blending theory to align seamlessly with the practical context at hand. This adaptive approach ensures that I effectively
								address the unique challenges and demands of each situation I encounter.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h3">Data Driven</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								As a product leader, I believe in thoroughly analyzing the entire customer journey to challenge assumptions and uncover valuable opportunities for enhancing the product experience. My commitment lies in leveraging data and analytics to drive innovation, ensuring that
								the products we deliver consistently surpass our customers' expectations. By combining strategic thinking with customer-centric insights, I strive to lead the team towards creating exceptional and impactful solutions.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} textAlign="left" justifyContent={"right"} sx={{ textAlign: sectionTitleAlignment }}>
						<Box pt={4}>
							<Typography variant="h3">Certifications</Typography>
							<Typography variant="body1" pt={2} sx={{ fontWeight: "400" }}>
								Throughout my career, I have obtained several certifications, particularly during my tenure as a software engineer, where I focused on mastering Microsoft technologies and earned various Microsoft certifications. While those are noteworthy, I will refrain from listing
								them at this time.
								<br />
								<br />
								As for product management, I must admit that I have come across numerous certifications, but I have been somewhat skeptical about their true significance. Many certifications merely demonstrate the ability to complete workshops rather than truly showcasing one's
								expertise and capabilities as a product manager. For this reason, I believe these certifications do not significantly aid employers in determining my proficiency as a PM.
								<br />
								<br />
								Nevertheless, I do possess several papers and accomplishments in the field of product management that I can share with you. These documents provide a more meaningful representation of my skills and experiences in this domain.
							</Typography>
						</Box>
						<Box mt={8} display={"flex"} gap={2} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"}>
							<Box sx={sxImageBox}>
								<img src={"/certificate_cspo.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/certificate_dojo.png"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/certificate_soci.jpeg"} alt="logo" width={"100%"} />
							</Box>
							<Box sx={sxImageBox}>
								<img src={"/certificate_scrum.jpeg"} alt="logo" width={"100%"} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Layout content={<ContentElement />} header={<Header />} />
		</React.Fragment>
	);
}
